/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Directory } from '@capacitor/filesystem';
import { IonRefresher } from '@ionic/angular';
import { concat, EMPTY, forkJoin, from, Observable, of, Subscription, throwError, timer } from 'rxjs';
import { catchError, concatMap, concatMapTo, map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { Content, Customtour, Voucher } from 'src/app/models/FromApiModels';
import { CdnResult, ContentsReturnValue, SynchroReturnValue, CustomtourReturnValue, UpdatedContent, UpdatedVoyage, VoucherCdnResult, PointInteretCategorie } from 'src/app/models/Models';
import { blobTo64, securityForkJoin } from 'src/app/utils/functions';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';
import { FileService } from './file.service';
import { HttpService } from './http.service';
import { ProgressionService } from './progression.service';
import { UtilsService } from './utils.service';


@Injectable({
  providedIn: 'root'
})
export class SynchroService {

  homePageSubscriptionRefresher: [Subscription, IonRefresher] =  null;

  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private fileService: FileService,
    private progressionService: ProgressionService,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  appVersionIsValid(appVersion: string, apiAppVersion: string) {
    const regex = /\d+/g;
    const matchAppVersion = appVersion.match(regex);
    const matchApiAppVersion = apiAppVersion.match(regex);
    if (!Array.isArray(matchAppVersion) || !Array.isArray(matchApiAppVersion)) {
      return true;
    } else {
      const x = index => arr => Number(arr[index]);
      const first = x(0);
      const majorAppVersion = first(matchAppVersion);
      const majorApiAppVersion = first(matchApiAppVersion);
      if (majorAppVersion < majorApiAppVersion) {
        return false;
      } else if (majorAppVersion === majorApiAppVersion) {
        const second = x(1);
        const minorAppVersion = second(matchAppVersion);
        const minorApiAppVersion = second(matchApiAppVersion);
        if (minorAppVersion < minorApiAppVersion) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  synchro(force: boolean): Observable<any> {
    return this.dataService.getUser().pipe(
      map(_ => _.id),
      concatMap(clientId => this.httpService.apiGetT<SynchroReturnValue>({ uriTail: `synchro/check-update` }).pipe(
        catchError(err => { // Si retour code 401 (ex: token invalide), logout forcé
          if (Capacitor.getPlatform() !== 'web' && err?.error?.code === 401) {
            this.utilsService.flashToast('Votre token de connexion est invalide ou doit être renouvelé. vous allez être redirigé(e) vers la page de connexion.');
            this.router.navigateByUrl('/logout');
            return EMPTY;
          } else {
            return throwError(err);
          }
        }),
        mergeMap(_ => { // Si la version retournée par l'api et celle de l'app ne concordent pas, redirection vers page force-update
          const { appVersion } = environment;
          if (!this.appVersionIsValid(appVersion, _.app_version)) {
            this.router.navigateByUrl('/force-update');
            return EMPTY;
          } else {
            return of(_);
          }
        }),
        mergeMap(res => this.retrievePointsInteretCategories().pipe(
          map(_ => res)
        )),
        tap(_ => this.progressionService.checkUpdateOver()),
        mergeMap(res => concat(
          this.handleVoyages(res.customtours, clientId, force),
          this.handleContents(res.contents, clientId, force)
        ).pipe(
          toArray(),
          concatMap(([voyFilesFetch$, contFilesFetch$]) => concat(voyFilesFetch$,contFilesFetch$).pipe(
            toArray(),
            map(([handleVoyagesReturnValue, handleContentsReturnValue]) => ({
              fetchFilesResults: {
                images: [
                  ...handleVoyagesReturnValue.cdnResults,
                  ...handleContentsReturnValue.cdnResults,
                ],
                vouchers: [
                  ...handleVoyagesReturnValue.vouchersCdnResults,
                ]
              },
              res
            }))
          ))
         ),
        ),
        tap(_ => this.progressionService.sychroOver()),
      )),
      concatMap(({fetchFilesResults, res}) => this.dataService.setUser(res.client).pipe(
        concatMap(_1 => this.dataService.setSynchro(res).pipe(
          concatMap(_2 => this.dataService.setAppVersionlastSynchro(environment.appVersion).pipe(
            concatMap(_3 => this.dataService.setLastSynchroWasSuccessful(fetchFilesResults.images.every(result => result.success) && fetchFilesResults.vouchers.every(result => result.success)).pipe(
              concatMap(_4 => this.updateSetOfPresentImages(fetchFilesResults.images.filter(result => result.success).map(result => result)))
            ))
          ))
        ))
      ))
    );
  }

  retrievePointsInteretCategories() {
    return this.httpService.apiGetT<PointInteretCategorie[]>({ uriTail: 'points-of-interest/new-categories' }).pipe(
      mergeMap(res => this.dataService.setPointsInteretCategories(res))
    );
  }

  handleVoyages(updatedVoyages: UpdatedVoyage[], clientId, force: boolean): Observable<Observable<{ cdnResults: CdnResult[]; vouchersCdnResults: VoucherCdnResult[]}>> {
    return forkJoin([
      this.dataService.getVoyages(),
      this.dataService.getPointsOfInterest()
    ])
    .pipe(
      concatMap(([voyagesFromStorage, pointsOfInterestFromStorage]) => {
        const pointsOfInterestFromStorageMap = pointsOfInterestFromStorage.reduce((acc, cur) => acc.set(cur.customtour_id, cur), new Map());
        const updatedVoyagesMap = updatedVoyages.reduce((acc, cur) => acc.set(cur.id, cur.updated_at), new Map());
        const voyagesFromStorageMap = voyagesFromStorage.reduce((acc, cur) => acc.set(cur.id, cur), new Map());
        const voyagesToFetch = (
          force
          ? updatedVoyages
          : updatedVoyages
          .filter(voyage => !voyagesFromStorageMap.has(voyage.id) || Number(voyagesFromStorageMap.get(voyage.id).updated_at) < Number(voyage.updated_at))
        ).map(voyage => voyage.id);

        return this.getSynchroTours(voyagesToFetch).pipe(
          tap(_ => this.progressionService.voyagesHandleOver()),
          map(customtourReturnValue => this.getVouchers(clientId)(customtourReturnValue).pipe(
            tap(_ => this.progressionService.voyagesVouchersHandlerOver()),
            concatMap(vouchersCdnResults => this.getImagesFromRessources<CustomtourReturnValue>(clientId, 0.25)(
              {
                resources: [
                  ...customtourReturnValue.resources,
                  // ...customtourReturnValue.points_of_interest.reduce((acc, pointOfInterest) => [
                  //   ...acc,
                  //   ...pointOfInterest.points
                  //     .filter(point => point.image?.path)
                  //     .map(point => point.image)
                  // ], [])
                ]
              }
            ).pipe(
              concatMap(cdnResults => {
                  const { customtours, points_of_interest } = customtourReturnValue;
                  const returnedVoyagesMap = customtours.reduce((acc, cur) => acc.set(cur.id, cur), new Map());
                  const voyagesToKeep = [...updatedVoyagesMap.keys()].map(id => returnedVoyagesMap.get(id) || voyagesFromStorageMap.get(id));
                  const returnedPointsOfInterestMap = points_of_interest.reduce((acc, cur) => acc.set(cur.customtour_id, cur), new Map());
                  const pointsOfInterestToKeepMap = [...returnedPointsOfInterestMap.entries()].reduce((acc, [key, value]) => acc.set(key, value), pointsOfInterestFromStorageMap);
                  const pointsOfInterestToKeep = [...pointsOfInterestToKeepMap.values()];
                  return forkJoin([
                    this.dataService.setVoyages(voyagesToKeep.filter(e => e !== null && e !== undefined)),
                    this.dataService.setPointsOfInterest(pointsOfInterestToKeep)
                  ]).pipe(
                    mapTo(({cdnResults, vouchersCdnResults})),
                    tap(_ => this.progressionService.voyagesFilesHandleOver())
                  );
                })
            )
            ),
          )),
        );
      }),
    );
  }

  getSynchroTours(customtours: string[]): Observable<CustomtourReturnValue> {
    return customtours.length > 0
      ? this.httpService.apiPostT<CustomtourReturnValue>({ uriTail: 'synchro/customtours' }, { customtours })
      : of({ resources: [], customtours: [], points_of_interest: [] });
  }

  // getSynchroTours avec console.log
  // getSynchroTours(customtours: string[]): Observable<CustomtourReturnValue> {
  //   if (customtours.length > 0) {
  //     return this.httpService.apiPostT<CustomtourReturnValue>({ uriTail: 'synchro/customtours' }, { customtours }).pipe(
  //       tap(response => {
  //         // Afficher la réponse dans la console
  //         console.log('Réponse de l\'API pour getSynchroTours:', response);
  //       })
  //     );
  //   } else {
  //     const emptyResponse: CustomtourReturnValue = { resources: [], customtours: [], points_of_interest: [] };
  //     console.log('Aucun customtour fourni, retour de la réponse vide:', emptyResponse);
  //     return of(emptyResponse);
  //   }
  // }

  handleContents(genericContents, clientId, force: boolean) {
    return this.dataService.getContents().pipe(
      concatMap(contentsFromStorage => {
        const updatedContents: UpdatedContent[] = Object.entries(genericContents).reduce((acc, cur: [string, UpdatedContent[]]) => [...acc, ...cur[1].reduce((acc2, cur2) => [...acc2, cur2], [])], []);
        const contentsFromStorageMap = contentsFromStorage.reduce((acc, cur) => acc.set(cur.id, cur), new Map());
        const contentsToFetch = (
          force
          ? updatedContents
          : updatedContents
          .filter(content => !contentsFromStorageMap.has(content.id) || Number(contentsFromStorageMap.get(content.id).updated_at) < Number(content.updated_at))
        ).map(content => content.id);
        return this.getSynchroContents(contentsToFetch).pipe(
          tap(_ => this.progressionService.contentsHandleOver()),
          map(res => this.getImagesFromRessources<ContentsReturnValue>(clientId, 0.25)(res).pipe(
            concatMap((cdnResults => {
            const { contents } = res;
            const returnedContents: Content[] = Object.entries(contents).reduce((acc, cur: [string, Content[]]) => [...acc, ...cur[1].reduce((acc2, cur2) => [...acc2, cur2], [])], []);
            const returnedContentsMap = returnedContents.reduce((acc, cur) => acc.set(cur.id, cur), new Map());
            const contentsToKeep = updatedContents.map(ctt => returnedContentsMap.get(ctt.id) || contentsFromStorageMap.get(ctt.id));
            return this.dataService.setContents(contentsToKeep).pipe(
              mapTo(({cdnResults, vouchersCdnResults: [] as VoucherCdnResult[]}))
            );
          })
          )),
        ));
      }),
    );
  }

  getSynchroContents(contents: string[]): Observable<ContentsReturnValue> {
    return contents.length > 0
      ? this.httpService.apiPostT<ContentsReturnValue>({ uriTail: 'synchro/contents' }, { contents })
      : of({ resources: [], contents: { generic_contents: [], news: [] }});
  }

  // extractImagesFromHTML(contentHTML) {
  //   const arr1: string[] = contentHTML.split('src="../../').slice(1);
  //   return arr1.map(arrString => this.checkFilePresence(arrString.split('"')[0]));
  // }

  checkFilePresence(pathWithFileName): Observable<{ path: string; pres: boolean }> {
    return this.fileService.checkFile(pathWithFileName, Directory.Data).pipe(
      map(bool => ({ path: pathWithFileName, pres: bool }))
    );
  }

  cdn(pathWithFileName: string, clientId: string): Observable<CdnResult> {
    const pathAsArrayWithFileName = pathWithFileName.split('/');
    const name = [...pathAsArrayWithFileName].pop();

    return this.fetchFile(pathWithFileName, clientId).pipe(
      concatMap(blob => from(blobTo64(blob))),
      concatMap(base64 => this.fileService.writeDataToLocalDir(base64, pathWithFileName, Directory.Data)),
      map(_ => ({
        success: true,
        filePath: pathWithFileName
      })),
      catchError(err => of({
        success: false,
        filePath: pathWithFileName
      })) // TODO-pf prévoir un traitement si ça foire
    );
  }

  fetchFile(pathWithFileName: string, clientId: string): Observable<Blob> {
    const uriTail = `cdn?filepath=${pathWithFileName}`;
    return this.httpService.apiGetBlobT({ uriTail });
  }

  setSynchroAndDate(res: SynchroReturnValue, now: number): Observable<[SynchroReturnValue, number]> {
    return forkJoin([
      this.dataService.setSynchro(res),
      this.dataService.setSynchroDate(now),
    ]);
  }

  getImagesFromRessources<T>(clientId, num): (res: any) => Observable<CdnResult[]> {
    return res => {
      const resources = !(Capacitor.getPlatform() === 'web' && environment.displayFilesFromWebWhenPlatformWeb)
      ? res.resources
      : [];
      const uniqueUrls = [...new Set(Object.values(resources).map((ressource: { path: string }) => ressource?.path || '' ))].filter(path => path.length > 0);

      const {arrayOfVouchersUrls, arrayOfElseUrls} = uniqueUrls.reduce<{ arrayOfVouchersUrls: string[]; arrayOfElseUrls: string[]}>((acc, url) => {
        if ((/vouchers/i).test(url)) {
          return {
           ...acc,
           arrayOfVouchersUrls: [...acc.arrayOfVouchersUrls, url]
          };
        } else {
          return {
           ...acc,
           arrayOfElseUrls: [...acc.arrayOfElseUrls, url]
          };
        }
      }, {arrayOfVouchersUrls: [], arrayOfElseUrls: []});

      const getVouchersExternes = (arrLength: number) => arrayOfVouchersUrls.reduce<Observable<CdnResult[]>>((acc, url, i) => {
        if (i === 0) {
          return this.cdn(url, clientId).pipe(map(r => [r]));
        } else {
          return acc.pipe(
            concatMap(accR => this.cdn(url, clientId).pipe(
              map(r => [...accR, r]),
              tap(_ => this.progressionService.ressourceGot(arrLength, num)),
            ))
          );
        }
      }, of([]));

      const getAutres = (arrLength: number) => (arr: string[]) => arr.length > 0
      ? forkJoin(arr.map((url, i) => timer(100 * i).pipe(
        concatMapTo(this.cdn(url, clientId)),
        tap(_ => this.progressionService.ressourceGot(arrLength, num)),
      )))
      : of([] as CdnResult[]);

      const setOfUrlsfromMemory$ = this.dataService.getSetOfPresentFiles();
      const imagesFromRessource$ = setOfUrlsfromMemory$.pipe(
        map(set => (arrayOfElseUrls.filter(url => !set.has(url))))
      );
      return imagesFromRessource$.pipe(
        mergeMap(arr => {
          const arrLength = arr.length + arrayOfVouchersUrls.length;
          return getVouchersExternes(arrLength).pipe(
            concatMap(cdnArr => getAutres(arrLength)(arr).pipe(
              map(cdnArr2 => [...cdnArr, ...cdnArr2])
            ))
          );
        })
      );
    };
  }

  getVouchers(clientId): (res: CustomtourReturnValue) => Observable<VoucherCdnResult[]> {
    return res => {
      const allVouchers = !(Capacitor.getPlatform() === 'web' && environment.displayFilesFromWebWhenPlatformWeb)
      ? res.customtours.reduce<Observable<{path: string;pres: boolean; voucher: Voucher}>[]>(
        (acc, voyage: Customtour) => Array.isArray(voyage.vouchers) && voyage.vouchers.length > 0
        ? [...acc, ...voyage.vouchers.map(voucher => of({path: `vouchers/voucher_${voucher.id}.jpg`, pres: false, voucher})
          // this.checkFilePresence(`vouchers/voucher_${voucher.id}.jpg`)
          .pipe(
          map(_ => ({..._, voucher}))
        ))]
        : acc
        , []
      )
      : [];

      return securityForkJoin(allVouchers).pipe(
        // map(arr => arr.filter(f => !f.pres)),
        mergeMap(arr => arr.length > 0
          ? concat(...arr.map(_ => this.getVoucher(_.voucher, _.path, clientId))).pipe(
            tap(_ => this.progressionService.ressourceGot(arr.length, 0.2)),
            toArray())
          : of([])
          )
      );
    };
  }

  getVoucher(voucher: Voucher, path: string, clientId: string): Observable<VoucherCdnResult> {
    return this.fetchVoucherJpg(voucher.id).pipe(
      concatMap(blob => from(blobTo64(blob))),
      concatMap(base64 => this.fileService.writeDataToLocalDir(base64, path, Directory.Data)),
      map(_ => ({
        success: true,
        voucher
      })),
      catchError(err => of({
        success: false,
        voucher
      }))
    );
  }

  fetchVoucher(voucherId: string): Observable<Blob> {
    const uriTail = `vouchers/${voucherId}/pdf`;
    return this.httpService.apiGetBlobT({ uriTail });
  }

  fetchVoucherJpg(voucherId: string): Observable<Blob> {
    const uriTail = `vouchers/${voucherId}/jpeg`;
    return this.httpService.apiGetBlobT({ uriTail });
  }

  updateSetOfPresentImages(fetchFilesResults: CdnResult[]) {
    const paths = fetchFilesResults.map(cdnResult => cdnResult.filePath);
    // console.log('paths', paths);
    return this.dataService.getSetOfPresentFiles().pipe(
      // tap(_ => console.log('avant', _.entries())),
      map(set => paths.reduce((acc, path) => {
        set.add(path);
        return set;
      }, set)),
      // tap(_ => console.log('après', _.entries())),
      concatMap(set => this.dataService.setSetOfPresentFiles(set))
    );
  }
}
