import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.scss'],
})
export class NotificationsModalComponent {
  @Input() isOpen: boolean;
  @Input() notifications: any;
  @Output() setOpen = new EventEmitter<boolean>();


  constructor() { }

  closeModal() {
    this.setOpen.emit(false);
  }

  // TODO - il faudra definir toutes les classes
  getNotificationButtonClass(notificationType: string): string { 
    switch (notificationType) {
      case '01':
        return 'ion-bg-secondary';
      case '02':
        return 'ion-bg-greenhaze';
      default:
        return 'ion-bg-secondary';
    }  
  }

  // TODO - il faudra definir toutes les coleurs
  getNotificationIconColor(notificationType: string): string { 
    switch (notificationType) {
      case '01':
        return 'gold';
      case '02':
        return 'greenHaze'; 
      default:
        return 'gold';
    }  
  }

}
