import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent, HasMatchingURLPipe } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { ArrayNotEmptyModule } from './pipes/arrayNotEmpty/arrayNotEmpty-module';
import { PopoverModule } from './components/popover/popover.module';
import { ReservationModaleAlertPopoverModule } from './components/reservation-modale-alert-popover/reservation-modale-alert-popover.module';
import { ReservationPointInteretModalModule } from './components/reservation-point-interet-modal/reservation-point-interet-modal.module';
import { DateSelectorPopoverComponent } from './components/date-selector-popover/date-selector-popover.component';
import { ReactiveFormsModule } from '@angular/forms';
import { VoucherListeModalModule } from './components/voucher-liste-modal/voucher-liste-modal.module';
import { ConfirmationModalModule } from './confirmation-modal/confirmation-modal.module';
import { NotationModalModule } from './components/notation-modal/notation-modal.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MenuComponent } from './components/menu/menu.component';
import { NotificationsModalComponent } from './components/notifications-modal/notifications-modal.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    DateSelectorPopoverComponent,
    HasMatchingURLPipe,
    MenuComponent,
    NotificationsModalComponent
  ],
  imports: [
    BrowserModule,
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule,
    ArrayNotEmptyModule,
    PopoverModule,
    ReservationModaleAlertPopoverModule,
    ReservationPointInteretModalModule,
    VoucherListeModalModule,
    ConfirmationModalModule,
    NotationModalModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-FR' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }
}
