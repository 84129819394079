import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataFakeLoaderService {
  private fakeNotifications = 'assets/data/fake/notifications.json';
  private fakePortefeuille = 'assets/data/fake/portefeuille.json'

  constructor(private http: HttpClient) {}

  getFakeNotifications(): Observable<any> {
    return this.http.get<any>(this.fakeNotifications);
  }

  getFakePortefeuille(): Observable<any> {
    return this.http.get<any>(this.fakePortefeuille);
  }
}

