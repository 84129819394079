import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Customtour } from 'src/app/models/FromApiModels';
import { DataService } from 'src/app/services/data.service';
import { beforeDate, datePureFromDate, formatDateIntoDate, isFormatedDate } from 'src/app/utils/functions';
import { filterVoyage } from 'src/app/utils/functions';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  firstOngoingVoyage$: Observable<Customtour | null>;
  firstOngoingVoyageId: string | null = null;

  constructor(private router: Router, private menuController: MenuController, private dataService: DataService) { 
    const synchroInfos$ = this.dataService.observeVoyages();
    const voyagesValidated$ = synchroInfos$.pipe(
      map(voyages => (voyages || []).filter(voyage => filterVoyage(voyage, 'validated')) as Customtour[]),
    );

    this.firstOngoingVoyage$ = voyagesValidated$.pipe(
      mergeMap(voyages =>
        of(new Date()).pipe(
          map(now => {
            const finalNow = datePureFromDate(now);
            return (
              voyages.find(voyage => {
                const startDate = isFormatedDate(voyage.start)
                  ? new Date(formatDateIntoDate(voyage.start))
                  : null;
                const endDate = isFormatedDate(voyage.end)
                  ? new Date(formatDateIntoDate(voyage.end))
                  : null;
                return startDate && endDate && beforeDate(finalNow, endDate) && !beforeDate(finalNow, startDate);
              }) || null
            );
          })
        )
      )
    );
  }

  ngOnInit(): void {
    this.firstOngoingVoyage$.pipe(
      tap(voyage => {
        if (voyage) {
          this.firstOngoingVoyageId = voyage.id;
        }
      })
    ).subscribe();
  }

  closeMenu() {
    this.menuController.close();
  }

  checkAndNavigate(destinationUrl: string) {
    this.menuController.close();
    if (this.router.url !== destinationUrl) {
      // Naviguer uniquement si l'utilisateur n'est pas déjà sur la page cible
      this.router.navigate([destinationUrl]);
    }
  }

}
